
import {defineComponent, ref, watch} from "vue";
import InvoiceService from "@/core/services/InvoiceService";
import {LoadPanel} from "@/core/composite/composite";

export default defineComponent({
  name: "OriginalInvoice",
  props: {
    id: {type: String}
  },
  setup(props) {
    const document = ref<any>({})
    const load = (id) => {
      InvoiceService.original(id).then(res => {
        document.value = res;
      })
    }
    load(props.id)
    watch(() => props.id, cb => {
      load(cb);
    })
    return {
      ...LoadPanel(),
      document
    }
  }
})
