
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "InvoiceStatus",
  props: {
    status:{type: String}
  },
  setup(props) {
    const data = ref({name: '', clazz: ''})
    const build = (status) => {
      switch (status) {
        case 'ACTIVE':
          data.value = {name: 'Active', clazz: 'success'}
          break
        case 'CLOSED':
          data.value = {name: 'Active', clazz: 'secondary'}
          break
        case 'FINALIZED':
          data.value = {name: 'Finalized', clazz: 'info'}
          break
      }
    }
    build(props.status)
    watch(() => props.status, (cb) => {
      build(cb)
    })
    return {
      data
    }
  }
})
